import { createContext } from 'react';

export const AuthContext = createContext(null);

export const AuthContextState = {
    accessToken: null,
    isLoggedIn: false,
    firstName: null,
    lastName: null,
    email: null,
    userId: null,
    avatar: null,
    country: null,
    address: null,
    city: null,
    zipcode: null,
    currentCredits: null,
    // videoModal: null
}