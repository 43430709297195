import React, {Fragment, useState} from 'react';
/* eslint react/prop-types: 0 */


export default function PictureStep({
                                        id,
                                        image,
                                        doHandleClick,
                                    }) {

    return (
            <div className={'wizard_sizer_container'}>
                <div className={'wizard_sizer'}>
                    <img src={image} alt={""}
                     onClick={() => doHandleClick()}
                    >
                    </img>
                </div>
            </div>
    );
};