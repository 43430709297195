import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/general.scss';
import './styles/layout.scss';
import './styles/homepage.scss';
import './styles/conversations.scss';
import './styles/profile.scss';
import './styles/chat.scss';
import './styles/credits.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
