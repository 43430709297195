import React from 'react';
/* eslint react/prop-types: 0 */
import styles from './navs.scss';

const Nav = (props) => {
    const dots = [];

    function onClick(step){
        props.goToStep(step);
    }
    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep === i;
        dots.push((
            <span
                key={`step-${i}`}
                className={`dot ${isActive ? 'dot__active' : ''}`}
                onClick={() =>onClick(i)}
            >&bull;</span>
        ));
    }

    return (
        <div className={'wizard_nav'}>{dots}</div>
    );
};

export default Nav;
