import React, {Fragment, useState, useEffect, useRef} from 'react';
import StepWizard from "react-step-wizard";
//import StepWizard from '../../dist/react-step-wizard.min';

import Nav from './nav';
import Stats from './Stats';

import './wizard.scss';
import './transitions.scss';
import WizHeader from "./wiz_header";
import PictureStep from "./picture_step";

import step_size from "../../assets/steps/Self - Choose a size.png";
import step_enjoy from "../../assets/steps/Self - Enjoy.png";
import step_oor from "../../assets/steps/Self - Out of order.png";
import step_pay from "../../assets/steps/Self - Pay.png";
import step_cup from "../../assets/steps/Self - Place cup.png";
import step_somethingwrong from "../../assets/steps/Self - Something wrong.png";
// import step_welcome from "../../assets/steps/Self - Welcome.png";
import step_welcome from "../../assets/steps/Welcome.mp4";
import step_making from "../../assets/steps/Self - Making.png";
import step_cooling from "../../assets/steps/Self - Cooling wait.png";
import VideoStep from "./video_step";
// import bg from "../../assets/g12.jpeg";

/* eslint react/prop-types: 0 */

/**
 * A basic demonstration of how to use the step wizard
 */
const WorkflowWizard = () => {

    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `animated enterRight`,
            enterLeft: `animated enterLeft`,
            exitRight: `animated exitRight`,
            exitLeft: `animated exitLeft`,
            intro: `animated intro}`,
        },
        demo: true, // uncomment to see more
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
        if(timerId>0){
            clearTimeout(timerId);
        }
        currentStep=stats.activeStep;
        console.debug("Changed step to "+currentStep);

        switch(currentStep) {
            case STEP_NAME_MAKING:
                setTimeout(delayedGoToMakingOutcome, 5 * 1000);
                break;

            case STEP_NAME_COOLING:
                setTimeout(delayedCoolingOutcome, coolingTime * 1000);

                break;
            case STEP_NAME_OOR:
                timerId=setTimeout(timeoutOutcome, oorTime * 1000);
                break;
            default:
                timerId=setTimeout(timeoutOutcome, 10 * 1000);
                break;
        }

    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    const { SW, demo } = state;

  /*  function setHeaderInstance(instance){
        console.log("Setting header instance!");
        headerInstance=instance;
        console.log(instance);
    }

    var headerInstance;*/

    let currentStep= 1;
    let timerId=0;

    const wizHeaderRef = useRef(null);

    const timeoutOutcome = () => {
        console.debug("Timeout...");
        SW.goToStep(STEP_NAME_WELCOME);
    };
    const delayedGoToMakingOutcome = () => {
        console.debug("Making completed");

        if(errore){
            SW.goToStep(STEP_NAME_SOMETHINGWRONG);
        }else {
            SW.goToStep(STEP_NAME_ENJOY);
        }
    };
    const delayedCoolingOutcome = () => {
        console.debug("Cooling completed");

        SW.goToStep(STEP_NAME_MAKING );
    };

    function handleclick_welcome(){
        console.debug("Clicked welcome");
        SW.goToStep(STEP_NAME_SIZE );
    }

    function handleclick_size(){
        console.debug("Clicked size");
        if(!fotocellula){
            SW.goToStep(STEP_NAME_CUP );
        }
        if(fotocellula && !ready){
            SW.goToStep(STEP_NAME_COOLING );
            SW.goToNamedStep('cooling' );
        }
        if(fotocellula && ready){
            SW.goToStep(STEP_NAME_MAKING );
            SW.goToNamedStep('making' );
        }
    }
    function handleclick_making(){
        console.debug("Clicked making");
      //  SW.goToStep(STEP_NAME_SIZE);
     //   SW.goToNamedStep('size' );
    }
    function handleclick_cup(){
      //  SW.goToStep(STEP_NAME_SIZE);
        console.debug("Clicked cup");
    }
    function handleclick_pay(){
        console.debug("Clicked pay");
     //   SW.goToStep(STEP_NAME_SIZE);
    }
    function handleclick_oor(){
        console.debug("Clicked oor");
    //    SW.goToStep(STEP_NAME_SIZE);
    }
    function handleclick_enjoy(){
        console.debug("Clicked enjoy");
      //  SW.goToStep(STEP_NAME_SIZE);
    }
    function handleclick_somethingwrong(){
        console.debug("Clicked somethingwrong");
      //  SW.goToStep(STEP_NAME_SIZE);
    }
    function handleclick_cooling(){
        console.debug("Clicked cooling");
      //  SW.goToStep(STEP_NAME_SIZE);
    }

    let fotocellula=false;
    let errore=false;
    let ready=false;
    let coolingTime=3;
    let oorTime=15;
    let stato='produzione';

    const handleWizHeaderPropChange=(propName, value)=>{
        console.debug("Changed: "+propName+" to "+value );
        switch(propName){
            case 'fotocellula':
                fotocellula=value;
                if(fotocellula && currentStep===STEP_NAME_WELCOME){
                    SW.goToStep(STEP_NAME_SIZE);
                }
                if(fotocellula && currentStep===STEP_NAME_CUP){
                    handleclick_size();
                }
                break;
            case 'errore': errore=value;break;
            case 'ready': ready=value;break;
            case 'cooling': coolingTime=value;break;
            case 'oor': oorTime=value;break;
            case 'stato':
                stato=value;
                if(stato!=='produzione'){
                    SW.goToStep(STEP_NAME_OOR);
                }
                break;
        }
    }

    const STEP_NAME_WELCOME=1;
    const STEP_NAME_SIZE=2;
    const STEP_NAME_MAKING=3;
    const STEP_NAME_CUP=4;
    const STEP_NAME_PAY=5;
    const STEP_NAME_OOR=6;
    const STEP_NAME_ENJOY=7;
    const STEP_NAME_COOLING=8;
    const STEP_NAME_SOMETHINGWRONG=9;

    return (

        <div className='gelmatic_test_container'>
            <WizHeader handlePropChange={handleWizHeaderPropChange}/>
            <div className='container' style={{textAlign: "center"}}>
                <div className={`rsw-wrapper`}>
                    <StepWizard
                        onStepChange={onStepChange}
                        isHashEnabled
                        transitions={state.transitions} // comment out for default transitions
                        nav={<Nav />}
                        instance={setInstance}
                    >
                        <VideoStep stepName={"welcome"} video={step_welcome} doHandleClick={handleclick_welcome}  />
                        <PictureStep image={step_size} stepName={"size"} doHandleClick={handleclick_size} />
                        <PictureStep image={step_making} stepName={"making"} doHandleClick={handleclick_making} />
                        <PictureStep image={step_cup} stepName={"cup"} doHandleClick={handleclick_cup} />
                        <PictureStep image={step_pay} stepName={"pay"} doHandleClick={handleclick_pay} />
                        <PictureStep image={step_oor} stepName={"oor"} doHandleClick={handleclick_oor} />
                        <PictureStep image={step_enjoy} stepName={"enjoy"} doHandleClick={handleclick_enjoy} />
                        <PictureStep image={step_cooling} stepName={"cooling"} doHandleClick={handleclick_cooling} />
                        <PictureStep image={step_somethingwrong} stepName={"somethingwrong"} doHandleClick={handleclick_somethingwrong} />


                    </StepWizard>
                </div>
            </div>
        </div>
    );
};

export default WorkflowWizard;

/** Demo of using instance */
const InstanceDemo = ({ SW }) => (
    <Fragment>
        <h4>Control from outside component</h4>
        <button className={'btn btn-secondary'} onClick={SW.previousStep}>Previous Step</button>
        &nbsp;
        <button className={'btn btn-secondary'} onClick={SW.nextStep}>Next Step</button>
        &nbsp;
        <button className={'btn btn-secondary'} onClick={() => SW.goToNamedStep('progress')}>Go to 'progress'</button>
    </Fragment>
);

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */


/** Steps */

const Second = props => {
    const validate = () => {
        if (confirm('Are you sure you want to go back?')) { // eslint-disable-line
            props.previousStep();
        }
    };

    return (
        <div>
            { props.form.firstname && <h3>Hey {props.form.firstname}! 👋</h3> }
            I've added validation to the previous button.
            <Stats step={2} {...props} previousStep={validate} />
        </div>
    );
};

const Progress = (props) => {
    const [state, updateState] = useState({
        isActiveClass: '',
        timeout: null,
    });

    useEffect(() => {
        const { timeout } = state;

        if (props.isActive && !timeout) {
            updateState({
                isActiveClass: 'loaded',
                timeout: setTimeout(() => {
                    props.nextStep();
                }, 3000),
            });
        } else if (!props.isActive && timeout) {
            clearTimeout(timeout);
            updateState({
                isActiveClass: '',
                timeout: null,
            });
        }
    });

    return (
        <div className={'progress-wrapper'}>
            <p className='text-center'>Automated Progress...</p>
            <div className={`progress-cont ${state.isActiveClass}`}>
                <div className={`progress-bar progress-bar-striped`} />
            </div>
        </div>
    );
};

const Last = (props) => {
    const submit = () => {
        alert('You did it! Yay!') // eslint-disable-line
    };

    return (
        <div>
            <div className={'text-center'}>
                <h3>This is the last step in this example!</h3>
                <hr />
            </div>
            <Stats step={5} {...props} nextStep={submit} />
        </div>
    );
};