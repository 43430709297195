import React, {Fragment, useState} from 'react';
/* eslint react/prop-types: 0 */


export default function VideoStep({
                                        id,
                                        video,
                                        doHandleClick,
                                    }) {

    return (
            <div className={'wizard_sizer_container'}>
                <div className={'wizard_sizer'}>
                    <video autoPlay muted loop
                       onClick={() => doHandleClick()}
                    >
                    <source src={video} type={"video/mp4"} />
                    </video>
                </div>
            </div>
    );
};