
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext, AuthContextState } from './context/AuthContext';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import WorkflowWizard from "./components/WorkflowWizard/workflowWizard";


import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const [authState, setAuthState] = useState(AuthContextState);

  

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Routes>
              <Route path="/" element={<WorkflowWizard />} />
        </Routes>
      </AuthContext.Provider>
      <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          limit={1}
        />
        {/* Same as */}
    </BrowserRouter>
  );
}

export default App;
