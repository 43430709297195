import React, {useState} from 'react';
/* eslint react/prop-types: 0 */
import styles from './navs.scss';
import Switch from "../../widgets/switch";
import PropTypes from "prop-types";
import WorkflowWizard from "./workflowWizard";


const WizHeader = ({handlePropChange})=> {
    //setInstance(props);
    const [enableErrore, setEnableErrore] = useState(false);
    const [enableFotocellula, setEnableFotocellula] = useState(false);
    const [enableReady, setEnableReady] = useState(false);

    /* if (WizHeader.propTypes) {
         console.log('Button propTypes:', WizHeader.propTypes);
     }*/
    function ssetEnableFotocellula(val){
        setEnableFotocellula(val);
        handlePropChange('fotocellula', val);
    }
    function ssetEnableErrore(val){
        setEnableErrore(val);
        handlePropChange('errore', val);
    }
    function ssetEnableReady(val){
        setEnableReady(val);
        handlePropChange('ready', val);
    }

    const ssetRaffreddamento = (event) => {
        const newValue = event.target.value;
        handlePropChange('cooling', newValue);
    };
    const ssetOor = (event) => {
        const newValue = event.target.value;
        handlePropChange('oor', newValue);
    };
    const ssetStato = (event) => {
        const newValue = event.target.value;
        handlePropChange('stato', newValue);
    };

    return (
        <div className={'wiz_header'} >
            <div className="workflow_wizard__sources__checkwrapper">
                <span className="workflow_wizard__sources__checkwrapper_label">Stato macchina</span>
                <select
                    onChange={ssetStato}
                >
                    <option value={'produzione'}>Produzione</option>
                    <option value={'stop'}>Stop</option>
                    <option value={'standby'}>Standby</option>
                </select>
            </div>
            <div className={'wiz_stati_flags'}>
            <div className="workflow_wizard__sources__checkwrapper">
                <span className="workflow_wizard__sources__checkwrapper_label">Fotocellula presenza coppetta</span>
                {/* <div  className="workflow_wizard__sources__checkwrapper_inner"> */}
                    <Switch
                        isOn={enableFotocellula}
                        handleToggle={() => ssetEnableFotocellula(!enableFotocellula)}
                        id={"fotocellulaCheck"}
                    />
                {/* </div> */}
            </div>
            <div className="workflow_wizard__sources__checkwrapper">
                <span className="workflow_wizard__sources__checkwrapper_label">Errore erogazione</span>
                {/* <div  className="workflow_wizard__sources__checkwrapper_inner"> */}
                    <Switch
                        isOn={enableErrore}
                        handleToggle={() => ssetEnableErrore(!enableErrore)}
                        id={"erroreCheck"}
                    />
                {/* </div> */}
            </div>
                <div className="workflow_wizard__sources__checkwrapper">
                    <span className="workflow_wizard__sources__checkwrapper_label">Macchina pronta per erogazione</span>
                         <Switch
                            isOn={enableReady}
                            handleToggle={() => ssetEnableReady(!enableReady)}
                            id={"readyCheck"}
                        />
                 </div>
             </div>

            <div className="workflow_wizard__sources__checkwrapper">
                <span className="workflow_wizard__sources__checkwrapper_label">Raffreddamento cilindro (sec)</span>
                <input
                    type={"text"}
                    defaultValue={"3"}
                    onChange={ssetRaffreddamento}
                >
                </input>
            </div>
            <div className="workflow_wizard__sources__checkwrapper">
                <span className="workflow_wizard__sources__checkwrapper_label">Fuori servizio (sec)</span>
                <input type={"text"}
                       defaultValue={"15"}
                       onChange={ssetOor}
                >
                </input>
            </div>

        </div>
    );
};

export default WizHeader;
