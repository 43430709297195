import React from 'react';
import './switch.css';

const Switch = ({ isOn, handleToggle, id }) => {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-switch-checkbox"
                id={id}
                type="checkbox"
            />
            <label
                className={`react-switch-label ${isOn ? "react-switch-label-on" : "react-switch-label-off"}`}
                htmlFor={id}
            >
                <span className={`react-switch-button ${isOn ? "react-switch-button-on" : "react-switch-button-off"}`} />
            </label>
        </>
    );
};

export default Switch;